import React, { useState, useEffect } from 'react'
import Box from '@lib/ui/core/Box';
import Typography from '@lib/ui/core/Typography'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import SEO from '@components/SEO'
import Top from '@components/Home/Top'
import SurveyForm from '@components/SurveyForm'
import { getBanks } from '@services/prismic'
import { getCategories } from '@services/api/subscription'

const Subscription = () => {
  const [categoriesData, setCategoriesData] = useState([])
  const [bankData, setBankData] = useState([])

  useEffect(() => {
    const fetchBanksData = async () => {
      const { results } = await getBanks({
        options: {
          pageSize: 100,
        },
      })
      setBankData(results)
    }
    if (!bankData.length) {
      fetchBanksData()
    }
  })

  useEffect(() => {
    const fetchCategoriesData = async () => {
      const res = await getCategories()
      if (res.status === 200) {
        setCategoriesData(res.data)
      }
    }
    fetchCategoriesData()
  }, [])

  if (!bankData.length) {
    return null
  }

  return (
    <Layout>
      <SEO title="subscpription" />
      <Top />
      <Container>
        <Box position="relative" mt="-1.5rem">
          <Box position="absolute" bottom="100%">
            <Typography variant="h1">
              追蹤指定 <br />信用卡資訊
            </Typography>
          </Box>
        </Box>
      </Container>
      <Container>
        <SurveyForm
          categoriesData={categoriesData}
          bankData={bankData}
        />
      </Container>
    </Layout>
  )
}

export default Subscription
