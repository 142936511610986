/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { omit } from 'ramda'
import { useStoreActions } from 'easy-peasy'
import { postForm } from '@services/api/subscription'
import { getCreditCards } from '@services/prismic'
import Message from '@components/Message'
import { navigate } from '@reach/router'
import FormUI from './FormUI'

const SurveyForm = ({ categoriesData, bankData }) => {
  // const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult);
  const updateFirstCardInfo = useStoreActions(({ offerSearch }) => offerSearch.updateFirstCardInfo);
  const updateCardPayload = useStoreActions(({ offerSearch }) => offerSearch.updateCardPayload);
  const updateCardChosen = useStoreActions(({ offerSearch }) => offerSearch.updateCardChosen);
  const [selectBanks, setSelectBanks] = useState(bankData.map((item) => {
    item.isShow = false
    item.selected = 0
    return item
  }))
  const [isTouchBank, setIsTouchBank] = useState(false)
  const [isTouchCategory, setIsTouchCategory] = useState(false)
  const [selectedCards, setSelectedCards] = useState([])
  const [categories, setCategories] = useState([])
  const [isShowCategories, setIsShowCategories] = useState(false)
  const [open, setOpen] = useState(false)
  const [toastMsg, setToastMsg] = useState(false)
  const [toastType, setToastType] = useState('error')
  const requireMsg = '此欄必須填寫'
  const telephoneRegExp = /^([5|6|8|9])\d{7}$/

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const Toast = (msg, type = 'error') => {
    setToastType(type)
    setToastMsg(msg)
    handleOpen()
  }

  const handleFormFormat = (values) => {
    let formData = { ...values }
    let { birth_month } = formData
    birth_month = birth_month.toString().padStart(2, '0')
    formData.birthday = `${formData.birth_year}-${birth_month}-00`
    formData.creditcards = selectedCards.map((o) => {
      const card = { creditcard_id: o.prismicId }
      return card
    })
    formData.subscription_categories = categories.filter((item) => item.isShow).map((category) => {
      const categoryItem = { subscription_category_id: category.id }
      return categoryItem
    })
    formData = omit(['isTouchFlag', 'birth_year', 'birth_month', 'accepted_terms'], formData)
    if (!formData.telephone) {
      formData = omit(['telephone'], formData)
    }
    return formData
  }

  const submitForm = async (data) => {
    const res = postForm(data)
    res.then((response) => {
      if (response.status === 200 && response.data.id) {
        localStorage.setItem('subscribe_email', true)
        const firstCardInfo = selectedCards.map((card) => {
          card = omit(['bankId'], card)
          return card
        })
        const chosenCard = selectedCards.map((card) => card.prismicId)
        updateFirstCardInfo(firstCardInfo);
        updateCardPayload(chosenCard);
        updateCardChosen(selectedCards);
        navigate("/subscription/complete")
        // fetchSearchResult();
      }
    }).catch((error) => {
      if (error.response && error.response.data.errors) {
        const errType = error.response.data.errors
        if (errType.birthday) {
          Toast('請選擇出生年月！')
        } else if (errType.email) {
          Toast('電郵地址格式不正確！')
        } else if (errType.creditcards) {
          Toast('請選擇信用卡！')
        } else if (errType.subscription_categories) {
          Toast('請選擇優惠方式！')
        } else {
          Toast('輸入有誤！')
        }
      } else {
        Toast('出現錯誤，請刷新後重試！')
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      isTouchFlag: false,
      birth_year: '',
      birth_month: '',
      gender: '',
      email: '',
      telephone: '',
      accepted_terms: false,
    },
    validationSchema: Yup.object({
      birth_year: Yup.string().required(requireMsg),
      birth_month: Yup.string().required(requireMsg),
      gender: Yup.string().required(requireMsg),
      email: Yup.string().email('電郵地址格式不正確').required(requireMsg),
      telephone: Yup.string().matches(telephoneRegExp, '電話號碼輸入有誤'),
      accepted_terms: Yup.boolean().oneOf([true], '請同意以上條款及細則'),
    }),
    onSubmit: (values) => {
      setIsShowCategories(false)
      const formData = handleFormFormat(values)
      submitForm(formData)
    },
  })

  useEffect(() => {
    setCategories(categoriesData.map((item) => {
      item.isShow = false
      return item
    }))
  }, [categoriesData])

  const handleChoose = (card) => {
    const isClicked = selectedCards.find((o) => o.prismicId === card.prismicId)
    if (isClicked) {
      // remove
      setSelectBanks(selectBanks.map((item) => {
        if (item.prismicId === card.bankId) {
          item.selected -= 1
        }
        return item
      }))
      setSelectedCards(selectedCards.filter((o) => o.prismicId !== card.prismicId))
    } else {
      setSelectBanks(selectBanks.map((item) => {
        if (item.prismicId === card.bankId) {
          item.selected += 1
        }
        return item
      }))
      setSelectedCards([...selectedCards, card])
    }
  }

  const toggleCreditCard = async (uid, prismicId) => {
    const { results } = await getCreditCards({
      filter: {
        bankId: prismicId,
      },
      options: {
        pageSize: 100,
      },
    });
    setSelectBanks(selectBanks.map((item) => {
      if (item.uid === uid) {
        item.isShow = !item.isShow
        results.map((card) => {
          const newCard = {}
          card.bankId = prismicId
          return newCard
        })
        item.subcards = results
      }
      return item
    }))
  }

  const toggleCategory = () => {
    setIsShowCategories(!isShowCategories)
  }

  const bankBlur = () => {
    if (!isTouchBank) {
      setIsTouchBank(true)
    }
  }

  const categoryBlur = () => {
    if (!isTouchCategory) {
      setIsTouchCategory(true)
    }
  }

  const hideCategories = () => {
    setIsShowCategories(false)
  }

  const handleCheckboxChange = (categoryId) => {
    setCategories(categories.map((item) => {
      if (item.id === categoryId) {
        item.isShow = !item.isShow
      }
      return item
    }))
  }

  const isCreditCardClicked = (prismicId) => selectedCards.find((o) => o.prismicId === prismicId)

  return (
    <>
      <FormUI
        formik={formik}
        requireMsg={requireMsg}
        isTouchBank={isTouchBank}
        isTouchCategory={isTouchCategory}
        bankBlur={bankBlur}
        categoryBlur={categoryBlur}
        handleChoose={handleChoose}
        toggleCreditCard={toggleCreditCard}
        toggleCategory={toggleCategory}
        hideCategories={hideCategories}
        isShowCategories={isShowCategories}
        handleCheckboxChange={handleCheckboxChange}
        isCreditCardClicked={isCreditCardClicked}
        categories={categories}
        selectedCards={selectedCards}
        selectBanks={selectBanks}
      />
      <Message open={open} message={toastMsg} severity={toastType} onClose={handleClose} autoHideDuration={4000} />
    </>
  )
}

export default SurveyForm
