import React from 'react'
import Link from '@lib/ui/core/Link'
import Typography from '@lib/ui/core/Typography'
import Box from '@lib/ui/core/Box'
import Checkbox from '@lib/ui/core/Checkbox'
import Button from '@lib/ui/core/Button'
import RadioGroup from '@lib/ui/core/RadioGroup'
import Radio from '@lib/ui/core/Radio';
import FormControlLabel from '@lib/ui/core/FormControlLabel'
import Select from '@lib/ui/core/Select'
import SvgIcon from '@lib/ui/core/SvgIcon'
import IconButton from '@lib/ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { ReactComponent as CrossSvgIcon } from '@assets/icons/cross.svg'
import { ReactComponent as Dropdown } from '@assets/icons/dropdown.svg'
import { ReactComponent as AddIcon } from '@assets/icons/add.svg'
import { ReactComponent as DecreaseIcon } from '@assets/icons/decrease.svg'
import FormInput from './FormInput'
import ErrorMsg from './ErrorMsg'
import FilterByCard from './FilterByCard'
import { SmallTickIcon } from './TickIcon'

const FormUI = (props) => {
  const {
    formik,
    requireMsg,
    handleChoose,
    isTouchBank,
    isTouchCategory,
    bankBlur,
    categoryBlur,
    toggleCreditCard,
    toggleCategory,
    hideCategories,
    isShowCategories,
    handleCheckboxChange,
    isCreditCardClicked,
    categories,
    selectBanks,
    selectedCards,
  } = props

  const monthData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => ({ value: month, text: `${month} 月` }))
  const year = new Date().getFullYear()
  const yearData = []
  for (let i = year; i >= year - 120; i -= 1) {
    const obj = { value: i, text: `${i} 年` }
    yearData.push(obj)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box my="3.75rem">
        <Typography variant="h1">
          秒速集齊你的專屬信用卡優惠
        </Typography>
        <Box mt="1rem">
          <Typography variant="body1">
            用２分鐘話我哋知你對邊張信用卡有興趣，之後將相關信用卡最新優惠集齊晒第一時間話畀你知！
          </Typography>
        </Box>
        <Box>
          <Box mt="2rem" mb=".5rem" display="flex" alignItems="center">
            <Box fontSize="2.5rem" mr=".5rem">1</Box>
            <Box>
              <Typography variant="h1">選擇你持有的信用卡*</Typography>
              <Typography variant="body1">(可選多張)</Typography>
            </Box>
          </Box>
          <Box>
            {selectBanks.map((item) => (
              <Box mt={1.3} key={item.prismicId}>
                <Box
                  height="2.4rem"
                  lineHeight="2.4rem"
                  bgcolor="#f5f5f5"
                  borderRadius="15px"
                  pl={2}
                  position="relative"
                  border={((formik.touched.isTouchFlag || isTouchBank) && !selectedCards.length) ? '1px solid #F54444' : 'none'}
                  onClick={() => toggleCreditCard(item.uid, item.prismicId)}
                  tabIndex={0}
                  onBlur={bankBlur}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography component="span">{item.name}</Typography>
                  <Box pl={1} clone>
                    <Typography component="span" color="textSecondary">{item.selected || null}</Typography>
                  </Box>
                  <Box position="absolute" top="0" right=".94rem" height="2.4rem" display="flex" alignItems="center">
                    <SvgIcon component={item.isShow ? DecreaseIcon : AddIcon} fontSize="1.2rem" />
                  </Box>
                </Box>
                {item.isShow ? <FilterByCard creditCards={item.subcards} isCreditCardClicked={isCreditCardClicked} handleChoose={handleChoose} /> : null}
              </Box>
            ))}
          </Box>
          {((formik.touched.isTouchFlag || isTouchBank) && !selectedCards.length) ? <ErrorMsg mt=".5rem">{requireMsg}</ErrorMsg> : null}
        </Box>
        <Box>
          <Box mt="2rem" mb=".5rem" display="flex" alignItems="center">
            <Box fontSize="2.5rem" mr=".5rem">2</Box>
            <Box>
              <Typography variant="h1">在挑選信用卡時，你最關注信用卡 所提供的那一種優惠？*</Typography>
            </Box>
          </Box>
          <Box position="relative">
            <Box onClick={toggleCategory} style={{ cursor: 'pointer' }}>
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                minHeight="2.4rem"
                bgcolor="#f5f5f5"
                borderRadius="15px"
                tabIndex={0}
                onBlur={categoryBlur}
                border={((formik.touched.isTouchFlag || isTouchCategory) && !categories.some((item) => item.isShow)) ? '1px solid #F54444' : 'none'}
                pl={2}
                pb={0.6}
              >
                {categories.some((item) => item.isShow)
                  ? (
                    <Box display="flex" alignItems="center" flexWrap="wrap" pr="2rem">
                      {categories.map((item) => (
                        item.isShow ? (
                          <Box
                            key={item.id}
                            display="flex"
                            alignItems="center"
                            bgcolor="background.default"
                            py={0.5}
                            pl={1.2}
                            mt={0.8}
                            mr={0.8}
                            borderRadius=".4rem"
                          >
                            <Typography>{item.name}</Typography>
                            <IconButton
                              p={0}
                              mx=".16rem"
                              mr=".4rem"
                              onClick={(e) => {
                                e.stopPropagation()
                                handleCheckboxChange(item.id)
                              }}
                            >
                              <SvgIcon component={CrossSvgIcon} fontSize="1rem" />
                            </IconButton>
                          </Box>
                        ) : null
                      ))}
                    </Box>
                  )
                  : (
                    <Box pt={0.6} clone>
                      <Typography component="span" color="textSecondary">請選擇優惠</Typography>
                    </Box>
                  )}
                <Box position="absolute" right=".94rem" height="2.4rem" display="flex" alignItems="center" justifyContent="center" mt={0.3}>
                  <SvgIcon component={Dropdown} fontSize=".92rem" />
                </Box>
              </Box>
            </Box>
            {isShowCategories ? (
              <Box
                position="relative"
                top="0"
                left="0"
                width="100%"
                boxShadow={9}
                bgcolor="background.default"
                zIndex="2"
                tabIndex={0}
                onMouseLeave={hideCategories}
                onBlur={hideCategories}
              >
                {categories.length > 0 && categories.map((item) => (
                  <Box
                    key={item.id}
                    px={1.2}
                    onClick={() => handleCheckboxChange(item.id)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                  >
                    <Box p={1} clone>
                      <Typography>{item.name}</Typography>
                    </Box>
                    {item.isShow ? <SmallTickIcon /> : null}
                  </Box>
                ))}
              </Box>
            ) : null}
            {((formik.touched.isTouchFlag || isTouchCategory) && !categories.some((item) => item.isShow)) ? <ErrorMsg mt=".5rem">{requireMsg}</ErrorMsg> : null}
          </Box>
        </Box>
        <Box>
          <Box mt="2rem" mb=".5rem" display="flex" alignItems="center">
            <Box fontSize="2.5rem" mr=".5rem">3</Box>
            <Box>
              <Typography variant="h1">留下你的個人資料</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width="7.5rem"><Typography>出生年月*</Typography></Box>
            <Box width={{ sm: '100px', md: '200px' }} flex={{ xs: 1, sm: 1, md: 'none' }} alignSelf="flex-start">
              <Select
                name="birth_month"
                border={formik.touched.birth_month && formik.errors.birth_month}
                {...formik.getFieldProps('birth_month')}
              >
                <MenuItem value="" disabled>
                  <Box pl=".9rem"><Typography variant="body1">月</Typography></Box>
                </MenuItem>
                {monthData.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    <Box pl=".9rem">{item.text}</Box>
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.birth_month && formik.errors.birth_month ? (<ErrorMsg mt=".5rem">{formik.errors.birth_month}</ErrorMsg>) : null}
            </Box>
            <Box width={{ sm: '100px', md: '200px' }} flex={{ xs: 1, sm: 1, md: 'none' }} alignSelf="flex-start">
              <Select
                name="birth_year"
                ml=".5rem"
                border={formik.touched.birth_year && formik.errors.birth_year}
                {...formik.getFieldProps('birth_year')}
              >
                <MenuItem value="" disabled>
                  <Box pl=".9rem"><Typography variant="body1">年</Typography></Box>
                </MenuItem>
                {yearData.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    <Box pl=".9rem">{item.text}</Box>
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.birth_year && formik.errors.birth_year ? (<ErrorMsg mt=".5rem">{formik.errors.birth_year}</ErrorMsg>) : null}
            </Box>
          </Box>
          <Box mt="1rem">
            <RadioGroup row aria-label="gender" name="gender">
              <Box width="7.5rem"><Typography>性别*</Typography></Box>
              <Box>
                <FormControlLabel
                  control={<Radio color="primary" name="gender" {...formik.getFieldProps('gender')} value="1" />}
                  label="男"
                />
              </Box>
              <Box ml="2rem">
                <FormControlLabel
                  control={<Radio color="primary" name="gender" {...formik.getFieldProps('gender')} value="2" />}
                  label="女"
                />
              </Box>
            </RadioGroup>
            {formik.touched.gender && formik.errors.gender ? (<ErrorMsg ml="7.5rem">{formik.errors.gender}</ErrorMsg>) : null}
          </Box>
        </Box>
        <Box>
          <Box mt="1.1rem" mb=".5rem" display="flex" alignItems="center">
            <Box fontSize="2.5rem" mr=".5rem">4</Box>
            <Box>
              <Typography variant="h1">留下你的聯絡方法</Typography>
            </Box>
          </Box>
          <Box display="flex" flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
            <Box width={{ xs: '100%', md: '50%' }}>
              <Box pt=".9rem" pb=".5rem" clone>
                <Typography>電郵地址*</Typography>
              </Box>
              <FormInput
                name="email"
                border={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (<ErrorMsg mb=".5rem">{formik.errors.email}</ErrorMsg>) : null}
            </Box>
            <Box width={{ xs: '100%', md: '50%' }} marginLeft={{ md: '10px' }}>
              <Box pt=".9rem" pb=".5rem" clone>
                <Typography>電話號碼 (選填)</Typography>
              </Box>
              <FormInput
                name="telephone"
                border={formik.touched.telephone && formik.errors.telephone}
                {...formik.getFieldProps('telephone')}
              />
              {formik.touched.telephone && formik.errors.telephone ? (<ErrorMsg>{formik.errors.telephone}</ErrorMsg>) : null}
            </Box>
          </Box>
        </Box>
        <Box mt="1.5rem">
          <Checkbox
            label={(
              <Typography>我已閱讀新傳媒集團個人<Link href="http://www.nmg.com.hk/privacy/" target="_blank">私隱政策</Link>，並同意接受以上條款及細則</Typography>
            )}
            name="accepted_terms"
            {...formik.getFieldProps('accepted_terms')}
          />
          {formik.touched.accepted_terms && formik.errors.accepted_terms ? (<ErrorMsg ml="2.1rem" mt=".5rem">{formik.errors.accepted_terms}</ErrorMsg>) : null}
          <Button data-ele-name="comfirm-subscribe" width="100%" mt="1.5rem" mb="4.25rem" type="submit">
            <Typography>提交</Typography>
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default FormUI
