import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { ReactComponent as Dropdown } from '@assets/icons/dropdown.svg'

const FormControlRoot = styled(FormControl)`
  width: ${(props) => (props.width ? props.width : '100%')};
`

const SelectRoot = styled(Select)`
  border-radius: 15px;
  background: #f5f5f5;
  height: 2.4rem;
  margin-left: ${(props) => props.ml};
  border: ${(props) => (props.border ? '1px solid #F54444' : 'none')}; 
  
  &::before, &::after, &:hover:not(.Mui-disabled):before {
    border: none;
  }
	
	&>.MuiSelect-select {
		padding-left: 20px;
	}

  &>.MuiSelect-icon {
    width: .92rem;
    right: .76rem;
    top: .92rem;
  }
`

const SelectWrap = ({ width, border, children, ...props }) => (
  <FormControlRoot width={width}>
    <SelectRoot
      displayEmpty
      border={border}
      {...props}
      IconComponent={Dropdown}
    >
      {children}
    </SelectRoot>
  </FormControlRoot>
)

export default SelectWrap
