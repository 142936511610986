import React from 'react'
import styled from 'styled-components'
import Box from '@lib/ui/core/Box'
import Grid from '@lib/ui/core/Grid'
import Typography from '@lib/ui/core/Typography'
import TickIcon from './TickIcon'

const PopupRoot = styled(Box)`
  background: #f5f5f5;
  width: 100%;
  margin-top: -2rem;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
`

const Img = styled.img`
  src: ${(props) => props.imgUrl};
  alt: ${(props) => props.imgUrl};
  width: 100%;
  border-radius: 5px;
`

const Card = ({ card, handleChoose, isCreditCardClicked }) => (
  <Box position="relative" style={{ paddingTop: '6px' }} clone>
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
    >
      <Box width="100%" style={{ cursor: 'pointer' }} height="0px" pb="63%" overflow="hidden">
        <Img
          src={card.card_image.url}
          alt={card.card_image.alt}
          onClick={() => handleChoose(card)}
        />
      </Box>
      {isCreditCardClicked(card.prismicId) && <TickIcon />}
      <Box my=".5rem">
        <Typography align="center">{card.name}</Typography>
      </Box>
    </Grid>
  </Box>
)

const FilterByCard = ({ creditCards, isCreditCardClicked, handleChoose }) => (
  <PopupRoot padding="4rem 1.4rem 2rem 1.4rem">
    <Grid justify="center" spacing={3} container>
      {
        creditCards.length > 0
          ? creditCards.map((card) => (
            <Box key={card.prismicId} clone>
              <Card
                card={card}
                isCreditCardClicked={isCreditCardClicked}
                handleChoose={handleChoose}
              />
            </Box>
          )) : <Box m="auto"><Typography>加載出錯</Typography></Box>
      }
    </Grid>
  </PopupRoot>
)

export default FilterByCard
