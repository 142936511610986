import React from 'react'
import styled from 'styled-components'
import Box from '@lib/ui/core/Box'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { ReactComponent as TickSvgIcon } from '@assets/icons/middle-tick.svg'

const TickWrap = styled(Box)`

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFE600;
`

const TickIcon = (props) => (
  <TickWrap {...props} position="absolute" top="-.4rem" right="0" width="1.9rem" height="1.9rem">
    <SvgIcon component={TickSvgIcon} fontSize="1.1rem" />
  </TickWrap>
)

export const SmallTickIcon = (props) => (
  <TickWrap {...props} width="1.22rem" height="1.22rem">
    <SvgIcon component={TickSvgIcon} fontSize=".7rem" />
  </TickWrap>
)

export default TickIcon
