import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxIcon from '@material-ui/core/internal/svg-icons/CheckBox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import smallTick from '@assets/icons/small-tick.svg'

const Img = styled.img`
  src: ${(props) => props.src};
  position: absolute;
  top: .94rem;
  left: .9rem;
  width: .8rem;
`

const CheckBoxIconRoot = styled(CheckBoxIcon)`
  color: #FFE600;

`

const FormControlLabelRoot = styled(FormControlLabel)`
  display: flex;
`

const CheckboxRoot = ({ label, ...props }) => (
  <FormControlLabelRoot
    control={<Checkbox {...props} checkedIcon={<Box display="inline-flex"><CheckBoxIconRoot /><Img src={smallTick} /></Box>} />}
    label={label}
  />
)

export default CheckboxRoot
