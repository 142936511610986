import React from 'react'
import RadioButtonIcon from '@material-ui/core/Radio/RadioButtonIcon'
import Radio from '@material-ui/core/Radio'
import Box from '@lib/ui/core/Box'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { ReactComponent as smallTick } from '@assets/icons/small-tick.svg'

const TickIcon = () => (
  <Box display="flex" bgcolor="primary.main" borderRadius="15px">
    <Box display="flex" clone>
      <RadioButtonIcon />
    </Box>
    <Box position="absolute" top="1rem" left=".94rem" clone>
      <SvgIcon component={smallTick} fontSize=".75rem" />
    </Box>
  </Box>
)

const RadioRoot = (props) => <Radio {...props} checkedIcon={<TickIcon />} />

export default RadioRoot
